// export const APP_API_URL = "http://127.0.0.1:8000/api";
// export const APP_URL = "http://127.0.0.1:8000";

export const APP_API_URL = "https://api.asebat.com/api";
export const APP_URL = "https://api.asebat.com";

export const APP_NAME = "ASEBât";

export const SIDEBAR_LINKS = [
  {
    id: 1,
    icon: "bi bi-cash-stack",
    text: "Caisse",
    url: "/",
    category: "Gestion des ventes"
  },
  // {
  //   id: 2,
  //   icon: "bi bi-check-circle",
  //   text: "Validations",
  //   url: "/validations",
  //   category: "Gestion des ventes"
  // },
  {
    id: 3,
    icon: "bi bi-people",
    text: "Clients",
    url: "/clients",
    category: "Gestion des ventes"
  },
  {
    id: 4,
    icon: "bi bi-cart-check",
    text: "Ventes",
    url: "/commandes",
    category: "Gestion des commandes"
  },
  {
    id: 5,
    icon: "bi bi-box-seam",
    text: "Approvisionnement",
    url: "/approvisionnement",
    category: "Gestion des commandes"
  },
  {
    id: 6,
    icon: "bi bi-box",
    text: "Produits",
    url: "/articles",
    category: "Gestion des produits"
  },
  {
    id: 12,
    icon: "bi bi-plus-circle",
    text: "Créer un produit",
    url: "/add-product",
    category: "Gestion des produits"
  },
  {
    id: 13,
    icon: "bi bi-tag",
    text: "Catégories",
    url: "/categories",
    category: "Gestion des produits"
  },
  {
    id: 14,
    icon: "bi bi-tags",
    text: "Sous-catégorie",
    url: "/sub-categories",
    category: "Gestion des produits"
  },
  {
    id: 17,
    icon: "bi bi-tags",
    text: "Marques",
    url: "/marques",
    category: "Gestion des produits"
  },
  {
    id: 15,
    icon: "bi bi-archive",
    text: "Unités",
    url: "/units",
    category: "Gestion des produits"
  },
  {
    id: 7,
    icon: "bi bi-truck",
    text: "Fournisseurs",
    url: "/fournisseurs",
    category: "Gestion des produits"
  },
  {
    id: 8,
    icon: "bi bi-bar-chart",
    text: "Rapports",
    url: "/rapports",
    category: "Analytique"
  },
  {
    id: 9,
    icon: "bi bi-person-badge",
    text: "Personnel",
    url: "/personnel",
    category: "Ressources humaines"
  },
  {
    id: 16,
    icon: "bi bi-person-badge",
    text: "Postes",
    url: "/roles-permissions",
    category: "Ressources humaines"
  },
  {
    id: 15,
    icon: "bi bi-shop",
    text: "Showroom",
    url: "/stores",
    category: "Administration"
  },
  {
    id: 10,
    icon: "bi bi-building",
    text: "Magasins",
    url: "/warehouses",
    category: "Administration"
  },
  {
    id: 18,
    icon: "bi bi-archive",
    text: "Formats",
    url: "/formats",
    category: "Gestion des produits"
  },
  {
    id: 19,
    icon: "bi bi-archive",
    text: "Liste des Ventes",
    url: "/admin/ventes",
    category: "Gestion des ventes"
  },
  {
    id: 21,
    icon: "bi bi-archive",
    text: "Résumé des Produits",
    url: "/admin/summary",
    category: "Gestion des produits"
  },
];