import React from "react";
import formatNumber, { convertNumberToWordsInFCFA, formatDateWithTime } from "../utils/functions";
import { APP_NAME } from "../utils";
import { QRCodeCanvas } from "qrcode.react";

export default function FactureComponent({ invoiceData, isCopy = true }) {

  return (
    <div className={`${isCopy ? 'invoice-copy second-copy' : 'invoice-copy'} relative`}>
      {/* <img src="/assets/img/logo.png" className="absolute rotate-45 z-0" alt={APP_NAME} /> */}
      <div className="flex flex-row justify-between items-start mt-10">
        <div className="">
          <img src="/assets/img/logo.png" height={100} width={100} alt={APP_NAME} />

          <p className="mt-3 font-medium text-sm tracking-widest">
            ASEBAT SARL
          </p>
          <p className="font-medium text-sm">C/1775 C, QUARTIER GBEDJEKOME - OUEDO</p>
          <p className="font-medium text-sm">BP 952  ABOMEY - CALAVI,BENIN</p>
          <p className="font-medium text-sm">IFU 3202434985932 - RCCMRB/ABC/24 B 7993</p>
          <p className="font-medium text-sm">+229 01 61 89 89 00</p>
        </div>

        {invoiceData.emecef_data && JSON.parse(invoiceData.emecef_data).codeMECeFDGI && (() => {
          try {
            const parsedData = JSON.parse(invoiceData.emecef_data);
            const decodedData = typeof parsedData === 'string' ? JSON.parse(parsedData) : parsedData;

            if (!decodedData.errors) {
              return (
                <div className="">
                  <p className="mb-0 font-semibold text-[10px]">
                    ELÉMENTS DE SÉCURITÉ DE LA FACTURE NORMALISÉE ­­­
                  </p>
                  <table className="border-collapse w-full text-sm whitespace-pre table-auto">
                    <tbody className="">
                      <tr>
                        <td className="p-1 border font-medium text-sm" rowSpan={4}>
                          <div className="flex flex-row justify-center items-center p-1">
                            <QRCodeCanvas
                              value={decodedData.qrCodeValue}
                              size={80} // Taille du QR code en pixels
                              bgColor={"#ffffff"} // Couleur de fond
                              fgColor={"#000000"} // Couleur du QR code
                              level={"L"} // Niveau de correction d'erreur (L, M, Q, H)
                            />
                          </div>
                        </td>
                        <td className="p-1 border font-medium text-[10px]">Code MECeF/DGI:</td>
                        <td className="p-1 border font-medium text-[10px]">{decodedData.codeMECeFDGI}</td>
                      </tr>
                      <tr>
                        <td className="p-1 border font-medium text-[10px]">MECeF NIM:</td>
                        <td className="p-1 border font-medium text-[10px]">{decodedData.nim}</td>
                      </tr>
                      <tr>
                        <td className="p-1 border font-medium text-[10px]">MECeF Compteurs:</td>
                        <td className="p-1 border font-medium text-[10px]">{decodedData.counters}</td>
                      </tr>
                      <tr>
                        <td className="p-1 border font-medium text-[10px]">MECeF Heure:</td>
                        <td className="p-1 border font-medium text-[10px]">{decodedData.dateTime}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            } else {
              // Afficher uniquement si des erreurs existent
              return null;
            }
          } catch (error) {
            console.error("Erreur lors de l'analyse JSON:", error);
            return null;
          }
        })()}
      </div>

      <div className="flex flex-row justify-between items-start mt-2">
        <div>
          <p className="font-medium text-sm">
            <span className="font-semibold">Nom de l'agent</span> : {invoiceData?.user?.name || "Non spécifié"}
          </p>
          <p className="font-medium text-sm">
            <span className="font-semibold">Magasin</span> : {invoiceData.store?.name || "Non spécifié"}
          </p>
          <p className="font-medium text-sm">
            <span className="font-semibold">Date</span> : {formatDateWithTime(invoiceData?.date_creation)}
          </p>
        </div>
      </div>

      <p className="text-right mt-2 font-medium text-xl">
        <span className="font-bold">
          {invoiceData.paye_complet
            ? invoiceData.statut_commande === "annulé"
              ? "Facture d'avoir"
              : "Facture"
            : "Décharge N°"}
        </span>
        {invoiceData.designation && ` : ${invoiceData.designation}`}
      </p>

      <div className="overflow-x-auto">
        <table className="border-collapse mt-3 w-full text-sm whitespace-pre table-auto">
          <thead>
            <tr className="">
              <th className="p-2 border font-semibold pe-7">Code client</th>
              <th className="p-2 border font-semibold text-start">Client</th>
              <th className="p-2 border font-semibold">IFU</th>
              <th className="p-2 border font-semibold">Téléphone</th>
            </tr>
          </thead>
          <tbody className="">
            <tr>
              <td className="p-2 border font-medium">{invoiceData?.customer?.client_code}</td>
              <td className="p-2 border font-medium">{invoiceData?.customer?.name}</td>
              <td className="p-2 border font-medium">{invoiceData?.customer?.ifu}</td>
              <td className="p-2 border font-medium">{invoiceData?.customer?.numero}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto">
        <table className="border-collapse w-full text-sm whitespace-pre table-auto">
          <thead>
            <tr className="">
              <th className="p-2 border font-semibold text-sm">
                Produit
              </th>
              <th className="p-2 border font-semibold text-sm">
                Prix Unitaire
              </th>
              <th className="p-2 border font-semibold text-sm pe-7">
                Quantité
              </th>
              <th className="p-2 border font-semibold text-sm pe-7">
                Taxe
              </th>
              {/* <th className="p-2 border font-semibold text-sm">
                Prix HT
              </th> */}
              <th className="p-2 border font-semibold text-sm">
                Prix Total
              </th>
            </tr>
          </thead>
          <tbody className="">
            {invoiceData?.products?.length > 0 ? (
              invoiceData.products.map((item, index) => (
                <tr key={index}>
                  <td className="p-2 border font-medium text-sm">
                    {item.name}
                  </td>
                  <td className="p-2 border font-medium text-sm">
                    {formatNumber(item.p2)} FCFA
                  </td>
                  <td className="p-2 border font-medium text-sm">
                    {item.quantite} {item.unite === 'Mettre carré' ? 'm²' : item.unite === 'Pièce' ? 'pc' : item.unite}
                  </td>
                  <td className="p-2 border font-medium text-sm">
                    {item.variant.is_taxable ? 'Taxé' : 'Exonéré'}
                  </td>
                  <td className="p-2 border font-medium text-sm">
                    {item.variant.is_taxable
                      ? `${formatNumber(
                        (item.p2 * item.quantite) /
                        (1.18 + (invoiceData.aib_value === 'A' ? 0.01 : invoiceData.aib_value === 'B' ? 0.05 : 0))
                      )} FCFA`
                      : `${formatNumber(item.p2 * item.quantite)} FCFA`}
                  </td>
                  {/* <td className="p-2 border font-medium text-sm">
                    {item.variant.is_taxable
                      {formatNumber(item.p2 * item.quantite)} FCFA
                      : '—'}
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="p-2 font-medium text-center text-sm">
                  Aucun produit dans le panier.
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={3} className="p-2 border font-semibold text-sm">
                Commentaires
              </td>
              <td colSpan={2} className="p-2 border font-semibold text-end text-sm">
                <b>Montant HT :</b> {formatNumber(invoiceData.montant_ht)} FCFA
              </td>
            </tr>
            <tr>
              <td
                colSpan={3}
                rowSpan={4}
                className="border-e-0 p-2 border font-medium text-sm"
              >
                Les marchandises vendues ne sont ni reprises ni échangées. <br />La disponibilité de votre achat n'est plus garantie 14 jours <br />après la date de facturation.
              </td>
              {/* <td className="p-2 border font-semibold text-end text-sm">
                <b>Montant HT :</b> {formatNumber(invoiceData.montant_ht)} FCFA
              </td> */}
            </tr>
            <tr>
              <td colSpan={5} className="p-2 border font-semibold text-end text-sm">
                {/* Montant de la TVA en espèces */}
                <b>T.V.A (18%) :</b> {formatNumber(invoiceData.tva)} FCFA
              </td>
            </tr>
            <tr>
              <td colSpan={5} className="p-2 border font-semibold text-end text-sm">
                {/* Montant de la TVA en espèces */}
                <b>AIB (0{`${(invoiceData.aib_value === 'A' ? 1 : invoiceData.aib_value === 'B' ? 5 : '')}`}%) :</b> {formatNumber(invoiceData.aib)} FCFA
              </td>
            </tr>
            {invoiceData.remise > 0 && (
              <tr>
                <td colSpan={5} className="p-2 border font-semibold text-end text-sm">
                  <b>Remise :</b> {formatNumber(invoiceData.remise)} FCFA
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={5} className="p-2 border font-semibold text-end text-sm">
                {/* Montant TTC (NET) */}
                <b>NET :</b> {formatNumber(invoiceData.montant_net)} FCFA
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {!invoiceData.paye_complet && (
        <div className="overflow-x-auto">
          <table className="border-collapse w-full text-sm whitespace-pre table-auto">
            <thead>
              <tr className="">
                <th className="p-2 border font-semibold text-start">Net à payer</th>
                <th className="p-2 border font-semibold text-start">Montant payé en avance</th>
                <th className="p-2 border font-semibold">Total dû</th>
              </tr>
            </thead>
            <tbody className="">
              <tr>
                <td className="p-2 border font-medium">{formatNumber(invoiceData.montant_net)} FCFA</td>
                <td className="p-2 border font-medium">{formatNumber(invoiceData.avance_payee)} FCFA</td>
                <td className="p-2 border font-medium">{formatNumber(invoiceData.total_du)} FCFA</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className="flex flex-wrap justify-between items-center gap-6 mt-3">
        <div>
          {invoiceData.paye_complet ? (
            <p className="mb-2 font-medium text-sm capitalize">
              <span className="font-bold">Montant en lettre</span> : {convertNumberToWordsInFCFA(Math.round(invoiceData?.montant_net))}
            </p>
          ) : (
            <>
              <p className="font-medium text-sm capitalize">
                <span className="font-bold">Net à payer en lettre</span> : {convertNumberToWordsInFCFA(invoiceData?.montant_net)}
              </p>
              <p className="mb-2 font-medium text-sm capitalize">
                <span className="font-bold">Montant payé en avance</span> : {convertNumberToWordsInFCFA(invoiceData?.avance_payee)}
              </p>
            </>
          )}
          <p className="font-medium text-sm">
            <span className="font-bold">Mode de règlement</span> : {invoiceData?.mode_reglement?.nom || "Non spécifié"}
          </p>
          <p className="font-medium text-sm">
            <span className="font-bold">Référence de la pièce</span> : {invoiceData?.reference_piece || "Non spécifiée"}
          </p>
        </div>
      </div>
    </div>
  );
}