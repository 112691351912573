import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import axios from 'axios';
import MainLayout from '../../../layout/main-layout';
import { APP_API_URL, APP_NAME } from '../../../utils';
import Loader from '../../../components/loader';
import formatNumber, { generateQuantityDescription } from '../../../utils/functions';

export default function AdminSummary() {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'date_creation', direction: 'descending' });
  // États pour les données
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/products-summary`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setAllData(response.data.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes :', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const result = allData.filter(item =>
      item.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(result);
    setCurrentPage(1);
  }, [searchTerm, allData]);

  const sortedItems = [...filteredData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedItems.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const requestSort = (key, subKey = null) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...filteredData].sort((a, b) => {
      // Récupérer les valeurs à comparer
      let aValue = subKey ? a[key]?.[subKey] : a[key];
      let bValue = subKey ? b[key]?.[subKey] : b[key];

      // Si la colonne est une chaîne qui doit être convertie en float
      if (['montant_net', 'benefice'].includes(key)) {
        aValue = parseFloat(aValue) || 0;
        console.log('aValue:', typeof (aValue));
        bValue = parseFloat(bValue) || 0;
      }

      // Comparer les valeurs
      if (aValue < bValue) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };

  const handleRefresh = async () => {
    await fetchData();
    setCurrentPage(1);
  };

  const exportToPDF = () => {
    const dataToExport = filteredData.length > 0 ? filteredData : currentItems;

    // Création d'un document PDF
    const doc = new jsPDF();
    doc.setFont("Arial", "normal");

    // Titre du PDF
    doc.text("Liste des Produits", 20, 20);

    // Entête de la table
    const headers = ["Référence", "Unité", "Quantité en stock", "Quantité validée", "Quantité réelle en stock"];

    // Données à imprimer
    const rows = dataToExport.map(item => {
      const quantityInStock = parseFloat(item.quantity_in_stock || 0);
      const quantityValidated = parseFloat(item.quantity_validated || 0);
      const quantityReal = quantityInStock + quantityValidated;

      return [
        item.product_name,
        item.unit_symbol,
        generateQuantityDescription(quantityInStock, item),
        generateQuantityDescription(quantityValidated, item),
        generateQuantityDescription(quantityReal, item)
      ];
    });

    // Ajouter la table au document PDF
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: 30,
      theme: 'striped',
      headStyles: { fillColor: [22, 160, 133] },
      styles: { fontSize: 10 }
    });

    // Téléchargement du fichier PDF
    doc.save(`Produits ${APP_NAME}.pdf`);
  };

  const totalQuantityInStock = filteredData.reduce((acc, item) => acc + parseFloat((item.quantity_in_stock || 0) * item.variant_price), 0);
  const totalQuantityValidated = filteredData.reduce((acc, item) => acc + parseFloat((item.quantity_validated || 0) * item.variant_price), 0);
  const totalQuantityReal = totalQuantityInStock + totalQuantityValidated;

  const exportToExcel = () => {
    const dataToExport = filteredData.length > 0 ? filteredData : currentItems;

    // Préparation des données sous forme de tableau
    const worksheetData = dataToExport.map(item => {
      const quantityInStock = parseFloat(item.quantity_in_stock || 0);
      const quantityValidated = parseFloat(item.quantity_validated || 0);
      const quantityReal = quantityInStock + quantityValidated;

      return {
        "Référence": item.product_name,
        "Unité": item.unit_symbol,
        "Quantité en stock": generateQuantityDescription(quantityInStock, item),
        "Quantité validée": generateQuantityDescription(quantityValidated, item),
        "Quantité réelle en stock": generateQuantityDescription(quantityReal, item)
      };
    });

    // Création d'un worksheet
    const ws = XLSX.utils.json_to_sheet(worksheetData);

    // Création d'un workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Produits');

    // Téléchargement du fichier Excel
    XLSX.writeFile(wb, `Produits ${APP_NAME}.xlsx`);
  };

  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=800,width=1200');
    const dataToPrint = filteredData.length > 0 ? filteredData : currentItems;

    // Construction des lignes de la table pour l'impression
    const tableRows = dataToPrint.map((item, index) => {
      const quantityInStock = parseFloat(item.quantity_in_stock || 0);
      const quantityValidated = parseFloat(item.quantity_validated || 0);
      const quantityReal = quantityInStock + quantityValidated;

      return `
      <tr>
        <td class="ps-3 font-semibold">${item.product_name}</td>
        <td>${item.unit_symbol}</td>
        <td>${generateQuantityDescription(quantityInStock, item)}</td>
        <td>${generateQuantityDescription(quantityValidated, item)}</td>
        <td>${generateQuantityDescription(quantityReal, item)}</td>
      </tr>
    `;
    }).join('');

    // Création du HTML complet à imprimer
    const tableHTML = `
    <html>
      <head>
        <title>Produits</title>
        <style>
          body { font-family: Arial, sans-serif; }
          table { width: 100%; border-collapse: collapse; }
          table, th, td { border: 1px solid black; }
          th, td { padding: 8px; text-align: left; }
        </style>
      </head>
      <body>
        <h1>Liste des Produits</h1>
        <table>
          <thead>
            <tr>
              <th>Référence</th>
              <th>Unité</th>
              <th>Quantité en stock</th>
              <th>Quantité validée</th>
              <th>Quantité réelle en stock</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
          </tbody>
        </table>
      </body>
    </html>
  `;

    // Ouvrir une nouvelle fenêtre et y ajouter le contenu HTML
    printWindow.document.write(tableHTML);
    printWindow.document.close();
    printWindow.print();
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <MainLayout>
      <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center mb-16'>
        <div className="pagetitle">
          <h1>Produits</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">Résumé des produits</li>
            </ol>
          </nav>
        </div>

        <div className='flex flex-col lg:flex-row gap-x-2 gap-y-4'>
          <div className='flex flex-row gap-x-2'>
            <button title='Exporter en PDF' onClick={exportToPDF} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-filetype-pdf"></i>
            </button>
            <button title='Exporter en Excel' onClick={exportToExcel} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-filetype-xls"></i>
            </button>
            <button title='Imprimer' onClick={handlePrint} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-printer"></i>
            </button>
            <button title='Rafraichir' onClick={handleRefresh} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>
        </div>
      </div>

      <section className='section'>
        <div className="card mb-4">
          <div className="card-header pb-4">
            <div className="row justify-content-between">
              <div className="col-md-3 flex flex-row gap-x-4">
                <div>
                  <label htmlFor="searchInput" className="form-label">
                    Rechercher
                  </label>
                  <input
                    id="searchInput"
                    type="search"
                    className="form-control"
                    placeholder="Rechercher..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-3 flex flex-row gap-x-4">
                <div>
                  <label className="form-label">Total quantité en stock</label>
                  <input type="text" className="form-control" readOnly value={formatNumber(totalQuantityInStock)} />
                </div>
              </div>
              <div className="col-md-3 flex flex-row gap-x-4">
                <div>
                  <label className="form-label">Total quantité validée</label>
                  <input type="text" className="form-control" readOnly value={formatNumber(totalQuantityValidated)} />
                </div>
              </div>
              <div className="col-md-3 flex flex-row gap-x-4">
                <div>
                  <label className="form-label">Total quantité réelle en stock</label>
                  <input type="text" className="form-control" readOnly value={formatNumber(totalQuantityReal)} />
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-0 printable print:w-full">
            <div className="table-responsive table-card">
              <table className="table text-nowrap mb-0 table-centered table-hover">
                <thead className="table-light">
                  <tr>
                    <th className="ps-3" onClick={() => requestSort('designation')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Référence
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('montant_net')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Unité
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('benefice')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Quantité en stock
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('customer_id')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Quantité validée
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th>
                      <button>Quantité réelle en stock</button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((item, index) => {
                      const quantityInStock = parseFloat(item.quantity_in_stock || 0);
                      const quantityValidated = parseFloat(item.quantity_validated || 0);
                      const quantityReal = quantityInStock + quantityValidated;

                      return (
                        <tr key={'item-' + index} className="align-middle">
                          <td className="ps-3 font-semibold">{item.product_name}</td>
                          <td>{item.unit_symbol}</td>
                          <td>
                            <p className='text-sm font-semibold'>
                              {generateQuantityDescription(quantityInStock, item)}
                            </p>
                            {formatNumber(quantityInStock * item.variant_price)} XOF
                          </td>
                          <td>
                            <p className='text-sm font-semibold'>
                              {generateQuantityDescription(quantityValidated, item)}
                            </p>
                            {formatNumber(quantityValidated * item.variant_price)} XOF
                          </td>
                          <td>
                            <p className='text-sm font-semibold'>
                              {generateQuantityDescription(quantityReal, item)}
                            </p>
                            {formatNumber(quantityReal * item.variant_price)} XOF
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td className="datatable-empty" colSpan="10">Aucune commande correspondant aux critères de recherche.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer d-md-flex justify-content-between align-items-center">
            <span>
              {currentItems.length > 0
                ? `Affichage de ${Math.max(indexOfFirstItem + 1, 0)} à ${Math.min(indexOfLastItem, currentItems.length)} sur ${sortedItems.length} entrée${sortedItems.length > 1 ? 's' : ''}`
                : `Aucune entrée à afficher`}
              {currentItems.length === 0 && allData.length > 0 && ` (filtré à partir de ${allData.length} commande${allData.length > 1 ? 's' : ''} totales)`}
            </span>

            {currentItems.length > 0 && (
              <nav className="mt-2 mt-md-0">
                <ul className="pagination mb-0">
                  <li className="page-item">
                    <a className="page-link" href="#!" onClick={handlePrevPage} disabled={currentPage === 1}>
                      Précédent
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#!" onClick={handleNextPage} disabled={currentPage === totalPages}>
                      Suivant
                    </a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};