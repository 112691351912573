import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import MainLayout from "../../layout/main-layout";
import Update from "./update";
import { APP_API_URL } from "../../utils";
import Loader from "../../components/loader";
import UIDropdown from "../../components/ui/ui-dropdown";
import Add from "../variant/add";
import Swal from "sweetalert2";

export default function ProductDetails() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState({ label: "Sélectionner une variante", value: null });
  const [formData, setFormData] = useState({
    name: "",
    sku: "",
    price: "",
    is_taxable: true // Par défaut taxable
  });
  const [isSaving, setIsSaving] = useState(false);

  const fetchProduct = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${APP_API_URL}/products/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct(response.data);
    } catch (err) {
      setError("Impossible de charger les détails du produit.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [id]);

  const handleVariantSelect = (option) => {
    const variant = product?.variants.find((v) => v.id === option.value);
    if (variant) {
      setSelectedVariant(variant);
      setFormData({
        name: variant.name,
        sku: variant.sku,
        price: variant.price,
        is_taxable: variant.is_taxable
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handleSave = async () => {
    if (!selectedVariant) return;

    setIsSaving(true); // Début du chargement

    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${APP_API_URL}/variants/${selectedVariant.id}`,
        {
          name: formData.name,
          sku: formData.sku,
          price: formData.price,
          is_taxable: formData.is_taxable
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      Swal.fire({
        icon: "success",
        title: "Succès",
        text: "La variante a été mise à jour avec succès !",
        timer: 2000,
        showConfirmButton: false,
      });
      setSelectedVariant({ label: "Sélectionner une variante", value: null })
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur est survenue lors de la mise à jour de la variante. Veuillez réessayer.",
      });
    } finally {
      setIsSaving(false); // Fin du chargement
    }
  };

  const handleRefresh = async () => {
    await fetchProduct();
  };

  if (loading) {
    return (
      <Loader />
    );
  }

  if (error) {
    return (
      <MainLayout>
        <div className="container">
          <section className="section error-404 min-h-screen d-flex flex-column align-items-center justify-content-center">
            <h1>Erreur</h1>
            <h2>{error}</h2>
            <Link className="btn" to={"/"}>
              Retour à l'accueil
            </Link>
          </section>
        </div>
      </MainLayout>
    );
  }

  if (!product) {
    return (
      <MainLayout>
        <div className="container">
          <section className="section error-404 min-h-screen d-flex flex-column align-items-center justify-content-center">
            <h1>404</h1>
            <h2>Le produit recherché est introuvable.</h2>
            <Link className="btn" to={"/"}>
              Retour à l'accueil
            </Link>
          </section>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center mb-16">
        <div className="pagetitle">
          <h1>Détails du produit</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">Détails complets d'un produit</li>
            </ol>
          </nav>
        </div>
      </div>

      <section className="section profile">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body pt-3">
                <ul className="nav nav-tabs nav-tabs-bordered" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-overview"
                      aria-selected="true"
                      role="tab"
                    >
                      Vue d'ensemble
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-edit"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                    >
                      Modifier le produit
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-settings"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                    >
                      Modifier une variante
                    </button>
                  </li>
                </ul>

                <div className="tab-content pt-2">
                  <div
                    className="tab-pane fade show active profile-overview"
                    id="profile-overview"
                    role="tabpanel"
                  >
                    <h5 className="card-title">Détails du produit</h5>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">Produit</div>
                      <div className="col-lg-9 col-md-8">{product?.name || "Aucun"}</div>
                    </div>
                    {product?.category?.nom && (
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Catégorie</div>
                        <div className="col-lg-9 col-md-8">{product.category.nom}</div>
                      </div>
                    )}
                    {product?.description && (
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Description</div>
                        <div className="col-lg-9 col-md-8">{product.description}</div>
                      </div>
                    )}
                  </div>

                  <div
                    className="tab-pane fade profile-edit pt-3"
                    id="profile-edit"
                    role="tabpanel"
                  >
                    <Update product={product} />
                  </div>

                  <div
                    className="tab-pane fade pt-3"
                    id="profile-settings"
                    role="tabpanel"
                  >
                    <div>
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12 mb-3">
                          <div>
                            <div className="flex flex-row justify-between items-center mb-2">
                              <label htmlFor="variant" className="form-label mb-0">Attribut de la variante</label>
                              <button
                                className='text-xs'
                                title='Ajouter une variante'
                                data-bs-toggle="modal"
                                data-bs-target="#addVariantModal"
                              >
                                <i className={`bi bi-plus`}></i> Ajouter une variante
                              </button>
                              <Add productId={product.id} reference={product.reference} refreshData={handleRefresh} />
                            </div>
                            <UIDropdown
                              options={product?.variants?.map(item => ({
                                label: item.name,
                                value: item?.id
                              }))}
                              selectedOption={selectedVariant}
                              onSelect={handleVariantSelect}
                              enableSearch={true}
                            />
                          </div>
                        </div>
                        {selectedVariant && (
                          <>
                            <div className="col-lg-4 col-sm-6 col-12 mb-3">
                              <label htmlFor="name" className="form-label">
                                Nom
                              </label>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                value={formData.name}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 mb-3">
                              <label htmlFor="sku" className="form-label">
                                Référence (SKU)
                              </label>
                              <input
                                type="text"
                                id="sku"
                                name="sku"
                                className="form-control"
                                value={formData.sku}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 mb-3">
                              <label htmlFor="sku" className="form-label">
                                Prix Unitaire de Vente
                              </label>
                              <input
                                type="number"
                                id="price"
                                name="price"
                                className="form-control"
                                value={formData.price}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <label htmlFor="is_taxable" className="form-label">Taxable</label>
                              <input
                                type="checkbox"
                                id="is_taxable"
                                name="is_taxable"
                                className="form-check-input ms-2"
                                checked={formData.is_taxable}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="flex flex-row justify-end mt-4 gap-x-4">
                              {isSaving ? (
                                <button
                                  type="button"
                                  disabled
                                  className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                                >
                                  <svg
                                    aria-hidden="true"
                                    role="status"
                                    className="inline w-4 h-4 me-2 text-white animate-spin"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="#E5E7EB"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                  Enregistrement...
                                </button>
                              ) : (
                                <button
                                  onClick={handleSave}
                                  className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                                >
                                  Enregistrer l'approvisionnement
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
